import React from "react"
import {
  Container,
  Main,
  Cutin,
  Content,
  Contact,
  Iframely,
  Seo,
  FeatureImage,
} from "../components"
import { FixedPageH1, FixedPageH2, P } from "../elements"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const swordPage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="制作実績"
        pagedesc="今まで杜川が手掛けてきた制作物の一例ページです。"
        pagepath={location.pathname}
        pageimg={data.saya.childImageSharp.original.src}
        pageimgw={data.saya.childImageSharp.original.width}
        pageimgh={data.saya.childImageSharp.original.height}
      />
      <Iframely />
      <FeatureImage fixed={data.saya2.childImageSharp.fixed} />
      <Cutin>
        <Main>
          <FixedPageH1>
            <h1>制作実績、参考価格</h1>
          </FixedPageH1>
          <Content>
            <FixedPageH2>朱皺革目塗肥後柄前</FixedPageH2>
            <Img
              fluid={data.sayaSample001.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <Img
              fluid={data.sayaSample001_2.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              技法：革目塗り（朱）
              <br />
              参考価格：１１２，０００円（税別）
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>馬革片手巻漆塗柄前</FixedPageH2>
            <Img
              fluid={data.sayaSample002.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <Img
              fluid={data.sayaSample002_2.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              技法：黒艶うるし塗り
              <br />
              参考価格：８９，６００円（税別）
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>甲丸糸組上巻石地塗肥後柄</FixedPageH2>
            <Img
              fluid={data.sayaSample003.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <Img
              fluid={data.sayaSample003_2.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              技法：石地塗り
              <br />
              参考価格：１４４，０００円（税別）
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>麻布諸捻巻柳生塗柄</FixedPageH2>
            <Img
              fluid={data.sayaSample004.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <Img
              fluid={data.sayaSample004_2.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              技法：布目塗り（うるみ）
              <br />
              参考価格：８９，６００（税別）
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>お問い合わせ</FixedPageH2>
            <Contact />
            <div className="iframely-embed">
              <div
                className="iframely-responsive"
                style={{ height: 140, paddingBottom: 0 }}
              >
                <a
                  href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                  data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                  aria-label="GoogleMaps"
                >
                  {null}
                </a>
              </div>
            </div>
          </Content>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    saya: file(relativePath: { eq: "saya.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }

    saya2: file(relativePath: { eq: "saya2.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    sayaSample001: file(relativePath: { eq: "saya-sample001.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sayaSample001_2: file(relativePath: { eq: "saya-sample001-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sayaSample002: file(relativePath: { eq: "saya-sample002.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sayaSample002_2: file(relativePath: { eq: "saya-sample002-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sayaSample003: file(relativePath: { eq: "saya-sample003.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sayaSample003_2: file(relativePath: { eq: "saya-sample003-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sayaSample004: file(relativePath: { eq: "saya-sample004.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sayaSample004_2: file(relativePath: { eq: "saya-sample004-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
   

  }
`

export default swordPage
